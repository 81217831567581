// アイコンのツールチップを表示するためのJavaScript
// _base.scssで定義したCSSクラスを使用する
// icon_helper.rbで定義したヘルパーメソッドを使用する

// ページが読み込まれた後に実行するためにDOMContentLoadedイベントを使用
document.addEventListener("DOMContentLoaded", function() {
    // data-bs-toggle="tooltip"属性を持つ要素を取得
    var tooltipElements = document.querySelectorAll('[data-bs-toggle="tooltip"]');

    // 各要素に対してツールチップを設定
    tooltipElements.forEach(function(element) {
        var tooltipText = element.getAttribute("data-title"); // ツールチップのテキストを取得
        var tooltip = document.createElement("div"); // ツールチップ用の要素を作成
        tooltip.classList.add("tooltip2"); // CSSクラスを追加
        tooltip.textContent = tooltipText; // ツールチップのテキストを設定

        // ツールチップを表示するイベントリスナーを追加
        element.addEventListener("mouseover", function() {
            // マウスが要素上にあるときにツールチップを表示
            tooltip.style.visibility = "visible";
        });

        // ツールチップを非表示にするイベントリスナーを追加
        element.addEventListener("mouseout", function() {
            // マウスが要素から離れたときにツールチップを非表示
            tooltip.style.visibility = "hidden";
        });

        // ツールチップを要素に追加
        element.appendChild(tooltip);
    });
});
