// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start();
require("@rails/activestorage").start();
require("channels");
require("clipboard");
require("flatpickr");
require("flatpickr/dist/l10n/ja.js");
require("flatpickr/dist/plugins/monthSelect/index.js");
require("jquery-timepicker/jquery.timepicker.js");
require("jquery-timepicker/jquery.timepicker.css");
require("video.js");
require("video.js/dist/video.min.js");
require("video.js/dist/video-js.min.css");
require("videojs-contrib-quality-levels");
require("videojs-hls-quality-selector");
require("select2");
require("uuid");
import './icons.js'; // アイコンのツールチップ表示用

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
global.Rails = Rails;

import "../stylesheets/application";
